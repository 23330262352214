<template>
  <div class="chart__wrapper chart-column" ref="chartPcaf4">
    <div class="chart">
      <!-- header -->
      <div class="chart__header">
        <div class="chart__header--left">
          <!-- @focusout.native="$refs.prev1.blur()" -->
          <button
            ref="prev1"
            type="button"
            class="chart-btn-action"
            :disabled="isDisabledPrev"
            @click="prev"
            @mouseover="handleMouseOverPrevBtn"
            @mouseleave="isMouseOverPrev = false"
            @mousedown="isBlurPrev = true"
            @mouseup="isBlurPrev = false"
            @touchstart="handleTouchStartPrevBtn"
            @touchend="handleTouchEndPrevBtn"
          >
            <img :src="prevIconUrl || prevIcon" alt="Prev" />
          </button>
        </div>
        <div class="chart__header--right">
          <!-- v-on:mouseleave="this.$refs.next1.blur()" -->
          <button
            ref="next1"
            type="button"
            class="chart-btn-action"
            :disabled="isDisabledNext"
            @click="next"
            @mouseover="handleMouseOverNextBtn"
            @mouseleave="isMouseOverNext = false"
            @mousedown="isBlurNext = true"
            @mouseup="isBlurNext = false"
            @touchstart="handleTouchStartNextBtn"
            @touchend="handleTouchEndNextBtn"
          >
            <img :src="nextIconUrl || nextIcon" alt="Next" />
          </button>
        </div>
      </div>

      <!-- body -->
      <div class="chart__body">
        <div class="chart__body--left" ref="monthLeft" :style="{ 'min-width': minWidth }">
          <div class="axisY">
            <div
              class="axis-item"
              v-for="(item, index) in axisYItemsSource"
              :key="index + '-axis-y'"
              :class="{ unit: index === 8 }"
              :style="{ bottom: `${item.percent}%` }"
            >
              {{ item.text }}
            </div>
          </div>
        </div>

        <div class="chart__body--center" ref="bodyCenter" @scroll="syncScroll('body')">
          <div class="chart-body-content">
          <div class="transition" :style="{ width: transitionWidth }"></div>

          <div
            class="chart-bar"
            @click="handleGetValueChart(item)"
            @mouseover="onMouseOver(item)"
            @mouseleave="onMouseLeave()"
            :class="{ selected: item.time_range === selectedTimeRange, hovered: item.time_range === hoverTimeRange }"
            v-for="(item, index) in chartValue"
            :key="index + '-bar-chart'"
          >
            <div class="active"></div>
            <div class="hover"></div>
            <div class="number-wrapper" v-if="hasNegativeNumber">
              <div
                class="positive-number"
                :style="{ height: `calc(100% - ${item.zero_value_percent})`, bottom: item.zero_value_percent }"
              >
                <div
                  v-for="(scope, index) in totalScopes"
                  :key="`scope-${scope}`"
                  :class="`bar bar--scope${scope} animation`"
                  :style="{ height: heightPositive(item, scope), background: getColor(index) }"
                >
                  <div
                    v-if="item['emission_scope' + scope] && item['emission_scope' + scope] >= 0"
                    @mouseover="toolTipOn(item, item[`detailemission_scope${scope}`])"
                    @mouseleave="toolTipOff"
                    @mousemove="updateTooltipPosition"
                    style="height: 100%"
                  ></div>
                </div>
              </div>
              <div class="zero-crossline" :style="{ height: item.zero_value_percent, width:' 100%'}"></div>
              <div class="negative-number" :style="{ height: item.zero_value_percent }">
                <div
                  v-for="(scope, index) in totalScopes"
                  :key="`scope-${scope}`"
                  :class="`bar bar--scope${scope} animation`"
                  :style="{ height: heightNegative(item, scope), background: getColor(index)}"
                >
                  <div
                    v-if="item['emission_scope' + scope] && item['emission_scope' + scope] < 0"
                    @mouseover="toolTipOn(item, item[`detailemission_scope${scope}`])"
                    @mouseleave="toolTipOff"
                    @mousemove="updateTooltipPosition"
                    style="height: 100%"
                  ></div>
                </div>
              </div>
            </div>
            <div v-else class="number-wrapper">
              <div
                v-for="(scope, index) in totalScopes"
                :key="`scope-${scope}`"
                :class="`bar bar--scope${scope} animation`"
                :style="{ height: item['emission_scope' + scope + '_percent'], background: getColor(index)}"
              >
                <div
                  v-if="item['emission_scope' + scope] >= 0"
                  @mouseover="toolTipOn(item, item[`detailemission_scope${scope}`])"
                  @mouseleave="toolTipOff"
                  @mousemove="updateTooltipPosition"
                  style="height: 100%"
                ></div>
              </div>
            </div>
            <!-- tooltip -->
            <div
              id="tooltip-column"
              class="tooltip"
              :style="{ left: tooltipPosition.x + 'px', top: tooltipPosition.y + 'px' }"
              v-if="isShowTooltip(item)"
            >
              <div class="tooltip-container">
                <p class="tooltip-title">{{ getNameText }}</p>
                <div class="tooltip-content">
                  <div class="tooltip-content-left">
                    <span>{{ $t('dashboard_main.label_year') }}</span>
                    <span>{{ getTypeText }}</span>
                    <span>{{ $t('dashboard_main.label_year_on_year_ratio') }}</span>
                  </div>
                  <div class="tooltip-content-right">
                    <span>{{ getYearAssets }}{{ getCurrentUnit }}</span>
                    <span>{{ getTotalEmissions }} {{ unit }}</span>
                    <span>{{ changeYearEveryValue }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="chart__body--right"></div>
      </div>
      </div>

      <!-- footer -->
      <div class="chart__footer">
        <div class="chart__footer--left" :style="{ minWidth: footerWidth + 'px' }">
          <!-- v-on:mouseleave="this.$refs.prev2.blur()" -->
          <button
            ref="prev2"
            type="button"
            class="chart-btn-action"
            :disabled="isDisabledPrev"
            @click="prev"
            @mouseover="handleMouseOverPrevBtn"
            @mouseleave="isMouseOverPrev = false"
            @mousedown="isBlurPrev = true"
            @mouseup="isBlurPrev = false"
            @touchstart="handleTouchStartPrevBtn"
            @touchend="handleTouchEndPrevBtn"
          >
            <img :src="prevIconUrl || prevIcon" alt="Prev" />
          </button>
        </div>

     
        <div class="chart__footer--center" ref="footerCenter" @scroll="syncScroll('footer')">
          <div class="axisX">
            <div
              class="axis-item"
              :class="{ active: item.value === selectedTimeRange }"
              v-for="(item, index) in axisXItemsSource"
              :key="index + '-axis-x'"
            >
              <span>{{ item.label }}</span>
            </div>
          </div>
        </div>

        <div class="chart__footer--right">
          <!-- v-on:mouseleave="this.blur();" -->
          <button
            ref="next2"
            type="button"
            class="chart-btn-action"
            :disabled="isDisabledNext"
            @click="next"
            @mouseover="handleMouseOverNextBtn"
            @mouseleave="isMouseOverNext = false"
            @mousedown="isBlurNext = true"
            @mouseup="isBlurNext = false"
            @touchstart="handleTouchStartNextBtn"
            @touchend="handleTouchEndNextBtn"
          >
            <img :src="nextIconUrl || nextIcon" alt="Next" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import '@/views/dashboard/components/emissions-by-period/yearly/chart-2/index.scss';
@media screen and (max-width: 1365px) {
  .chart__wrapper {
    .chart__footer {
      min-height: 72px;
      .chart__footer--left {
        * {
          display: none;
        }
      }
      .chart__footer--center {
        padding-right: 8px;
      }
      .chart__footer--right {
        * {
          display: none;
        }
      }
    }
  }
}
@media (min-width: 1024px) and (max-width: 1366px) {
  .chart__body--right {
    display: block !important;
  }
}
.chart-column {
  &.chart__wrapper {
    .chart__footer {
      &--center {
        .axisX {
          .axis-item {
            color: $monoBlack;
          }
        }
      }
    }
  }
}
.chart__wrapper {
  &.chart-column {
    .chart__body {
      display: flex;
      min-height: 522px;
      .chart__body--center {
        .chart-bar {
          min-height: 522px;

          .bar {
            width: 22.97px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .chart-column {
    &.chart__wrapper {
      .chart__body {
        .chart__body--center {
          .chart-bar {
            .bar {
              width: 21.57px;
            }
          }
        }
      }

      .chart__footer {
        .chart__footer--center {
          .axisX .axis-item {
            transform: unset;
            height: unset;
            padding: 20px 10px;
            word-break: break-all;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1366px) {
  .chart__wrapper {
    &.chart-column {
      .chart__body {
        display: flex;
        min-height: 480px;
        .chart__body--center {
          .chart-bar {
            min-height: 480px;
          }
        }
      }
    }
  }
}

.chart-column {
  &.chart__wrapper {
    .chart__body {
      .chart__body--left {
        z-index: 0;
      }
    }
  }
}
.tooltip {
  position: fixed;
  background-color: #000;
  color: #fff;
  font-size: 12px;
  z-index: 99;
}
.tooltip-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  align-self: stretch;
  border: 1px solid rgba(42, 42, 48, 0.1);
  background: #fff;
  box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24),
    0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17),
    0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13),
    0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11),
    0px 36px 33px 0px rgba(160, 181, 186, 0.07);
  color: #000;
  padding: 5px;
  min-width: 139px;
}
.tooltip-title {
  color: $monoBlack;
  font-size: 11px;
  font-weight: 700;
  line-height: 18px;
  margin-bottom: 4px;
}
.tooltip-content {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}
.tooltip-content-left,
.tooltip-content-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  span {
    color: #404d50;
    font-size: 11px;
    font-style: normal;
    line-height: 18px;
    letter-spacing: 0.33px;
  }
}
.tooltip-content-left {
  span {
    font-weight: 700;
  }
}
.tooltip-content-right {
  span {
    font-weight: 500;
  }
}
@media (max-width: 1024px) {
  .trend-chart-container {
    .chart__wrapper {
      width: 100%;
    }
  }
}
.chart__wrapper {
  width: 80%;
  padding: 0 16px 16px;
  &.chart-column {
    .chart__header {
      display: flex;
      justify-content: space-between;

      .chart__header--left {
        padding: 0 0 16px;
      }

      .chart__header--right {
        padding: 0 0 16px;
      }
    }
  }
  .chart__body {
    .chart__body--left {
      min-width: 53px;
      .axisY {
        justify-content: unset;
        position: relative;
        .axis-item {
          position: absolute;
          right: 0;
        }
      }
    }
    .chart__body--center {
      .transition {
        background-color: $monoWhite;
      }

      .number-wrapper {
        width: 100%;
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;
        position: relative;
        // .bar {
        //   width: 100%;
        // }
        .positive-number {
          flex: 1;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex-direction: column;
          position: absolute;
          width: 100%;
        }
        .negative-number {
          flex: 1;
          position: absolute;
        }
        .zero-crossline {
          border-top: 1px solid #ddd;
          height: 1px;
          width: 100%;
          position: absolute;
        }
      }
    }
  }
}
</style>
<script>
import { mapActions, mapState } from 'vuex';
import { TABLET_WIDTH, MOBILE_WIDTH } from '@/constants/screen-size';
import {
  formatValue,
  $_helper_isNumberType,
  $_helper_isNegativeNumber,
  math,
  formatBigNumber,
} from '@/concerns/newRegisterData/wijmo.helper';
const MAX_CHART_ITEMS = 6;
const AXIS_Y_ITEM_COUNT = 8;
import { formatNumberByConditions } from '@/utils/convertNumber';
import { getWidthYAxisByTextContent} from '@/utils/calcTextWidth';
import { getUnitText, isTotalEmission } from '@/utils/pcaf';
import { prepareChartAxisY, getMaxChartItemsCount, formatValueUnit, getMinWidthYAxisChartColumn } from '@/concerns/chart';
import debounce from 'lodash/debounce';
import { DASHBOARD_DISPLAY_UNIT, UNIT_SETTING  } from '@/constants/dashboard';
export default {
  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
    typeText: {
      type: String,
      default: '',
    },
    type: {
      type: Number,
      default: 0,
    },
    totalScopes: {
      type: Array,
      default: () => [],
    },
    assetsTypes: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      indexAtFirstBar: 0,
      min: 0,
      max: 500,
      axisYItemsSource: [
        {
          value: 0,
          text: '0',
        },
        {
          value: 100,
          text: '100',
        },
        {
          value: 200,
          text: '200',
        },
        {
          value: 300,
          text: '300',
        },
        {
          value: 400,
          text: '400',
        },
        {
          value: 500,
          text: 't-CO2',
        },
      ],
      axisXItemsSource: [],
      chartValue: [],
      selectedTimeRange: '',
      hoverTimeRange: '',
      isDisabledPrev: false,
      isDisabledNext: false,
      isFirstRender: true,
      isMouseOverNext: false,
      isMouseOverPrev: false,
      isBlurNext: false,
      isBlurPrev: false,
      transitionWidth: '100%',
      footerWidth: 0,
      minWidth: 0,
      showTooltip: false,
      rangeToolTip: '',
      tooltipPosition: { x: 0, y: 0 },
      rangeTooltipData: null,
      chartCenterWidth: 0,
      nextIconUrl: '',
      prevIconUrl: '',
      maxChartItem: 6
    };
  },
  async mounted() {
    await this.transformChartData();

    if (this.indexAtFirstBar + this.maxChartItem >= this.chartData.length) {
      this.isDisabledNext = true;
    }

    if (this.indexAtFirstBar <= 0) {
      this.isDisabledPrev = true;
    }
    this.footerWidth = this.$refs.monthLeft?.clientWidth;
    const debouncedHandleResize = debounce(() => {
      this.handleResize();
    }, 1000);
    window.addEventListener('resize', debouncedHandleResize);
    window.addEventListener('scroll', this.handleScroll);
    this.chartCenterWidth = this.$refs['chart-center']?.clientWidth;
    window.onresize = () => {
      this.chartCenterWidth = this.$refs['chart-center']?.clientWidth;
    };
  },
  computed: {
    ...mapState('pcaf', ['dashboard']),
    ...mapState('settingsDashboard', ['itemsSettings']),
    getUnit() {
      return this.itemsSettings[this.$store.state.userData.contractor]?.unitName || 't-CO2';
    },
    getCurrentUnit() {
      return  this.$i18n.locale === 'ja' ? this.$t('dashboard_main.label_year_of_month') : '';
    },
    heightPositive() {
      return (item, scope) => {
        return item['emission_scope' + scope] >= 0 ? item['emission_scope' + scope + '_percent'] : '0px';
      };
    },
    heightNegative() {
      return (item, scope) => {
        return item['emission_scope' + scope] < 0 ? item['emission_scope' + scope + '_percent'] : '0px';
      };
    },
    isShowTooltip() {
      return (item) => {
        return this.rangeToolTip === item?.time_range;
      };
    },
    getNameText() {
      return this.rangeTooltipData?.nameAssets;
    },
    getYearAssets() {
      return this.rangeTooltipData?.year;
    },
    getTotalEmissions() {
      const isTco2Unit = this.getUnit === 't-CO2';
      return isTotalEmission(this.type)
        ? this.formatNumberWithComma(formatValueUnit(this.rangeTooltipData?.total_emission, isTco2Unit), UNIT_SETTING.CO2)
        : this.formatNumberWithComma(this.rangeTooltipData?.total_amount, UNIT_SETTING.CURRENCY);
    },
    getTypeText() {
      return this.typeText;
    },
    unit() {
      return getUnitText(this.type, this.currency, this.getUnit);
    },
    changeYearEveryValue() {
      let ratioValue = this.rangeTooltipData?.ratio_amount || this.rangeTooltipData?.ratio_emission;
      ratioValue = ratioValue === null || ratioValue === undefined ? '-' : this.formatNumberWithComma(ratioValue, UNIT_SETTING.PERCENT)
      let unit = '';
      if (ratioValue !== '-') {
        unit = '%';
        if (this.rangeTooltipData?.up_amount === 1 || this.rangeTooltipData?.up_emission === 1) {
          unit += '↑';
        } else {
          unit += '↓';
        }
      }
      return ratioValue + unit;
    },
    nextIcon() {
      if (this.isDisabledNext) {
        this.isBlurNext = false;
        this.isMouseOverNext = false;
        return 'img/icons/next-disabled.svg';
      }

      if (this.isBlurNext) {
        return 'img/icons/next.svg';
      }

      if (this.isMouseOverNext) {
        return 'img/icons/next-hover.svg';
      }

      return 'img/icons/next.svg';
    },
    prevIcon() {
      if (this.isDisabledPrev) {
        this.isBlurPrev = false;
        this.isMouseOverPrev = false;
        return 'img/icons/prev-disabled.svg';
      }

      if (this.isBlurPrev) {
        return 'img/icons/prev.svg';
      }

      if (this.isMouseOverPrev) {
        return 'img/icons/prev-hover.svg';
      }

      return 'img/icons/prev.svg';
    },
    hasNegativeNumber() {
      for (const i of this.totalScopes) {
        const existNegativeNumber = this.chartValue.some((item) => {
          return item[`emission_scope${i}`] < 0;
        });

        if (existNegativeNumber) {
          return true;
        }
      }
      return false; 
    },
    getZeroCrossLineWidth() {
      if (window.innerWidth < 1024) {
        return this.chartCenterWidth / 6  + 'px';
      }
      return this.chartCenterWidth / 6 + 14  + 'px';
    }
  },
  watch: {
    chartData: {
      handler() {
        this.transformChartData();
        this.selectedFirstChartItem();
        this.nextIconUrl = '';
        this.prevIconUrl = '';
      },
      deep: true,
    },
    itemsSettings: {
      handler() {
        this.transformChartData();
        this.getWidthOfText();
      },
      deep: true,
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapActions('pcaf', ['updateTrendIndustryChart']),
    getValueAxisYChart(value) {
      if (value === DASHBOARD_DISPLAY_UNIT.tCO2Text || value === DASHBOARD_DISPLAY_UNIT.kgCO2Text) {
        return value;
      }

      const isTco2Unit = this.getUnit === 't-CO2';
      return formatNumberByConditions(formatValueUnit(value, isTco2Unit));
    },
    handleResize() {
      this.prepareChartDataValue();
      if (this.indexAtFirstBar + this.maxChartItem >= this.chartData.length) {
        this.isDisabledNext = true;
      } else {
        this.isDisabledNext = false;
      }

      if (this.indexAtFirstBar <= 0) {
        this.isDisabledPrev = true;
      } else {
        this.isDisabledPrev = false;
      }
    },
    syncScroll(origin) {
      if (origin === 'body') {
        this.$refs.footerCenter.scrollLeft = this.$refs.bodyCenter.scrollLeft;
      } else if (origin === 'footer') {
        this.$refs.bodyCenter.scrollLeft = this.$refs.footerCenter.scrollLeft;
      }
    },
    getColor(index) {
      return this.assetsTypes[this.totalScopes.length - index - 1 ]?.color;
    },
    handleScroll() {
      if (this.rangeToolTip) {
        this.toolTipOff();
      }
    },
    formatNumber(num) {
      return num === 0 ? num : Number(formatValue(num));
    },
    formatNumberWithComma(value, formatUnit) {
      if ($_helper_isNumberType(value)) {
        // return value?.toString()?.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,'); // add , to after 3 numbers
        return formatNumberByConditions(value?.toString(), { formatUnit })
      }
      return value;
    },
    updateTooltipPosition(event) {
      if (window.innerWidth >= TABLET_WIDTH) {
        const element = document.getElementById('tooltip-column');
        const width = element?.clientWidth;
        const height = element?.clientHeight;

        this.tooltipPosition.x = event.clientX - width / 2;
        this.tooltipPosition.y = event.clientY - height - 10;
      }
    },
    toolTipOn(item, rangeDataItem) {
      if (window.innerWidth < TABLET_WIDTH) {
        if (this.rangeToolTip) {
          this.toolTipOff();
        }
      } else {
        this.rangeToolTip = item.time_range;
        this.rangeTooltipData = rangeDataItem;
      }
    },
    toolTipOff(item) {
      this.rangeToolTip = '';
    },
    transformChartData() {
      const summary = this.chartData.map((item) => {
        let negativeTotal = 0;
        let positiveTotal = 0;
        for (let i = 0; i <= this.totalScopes.length - 1; i++) {
          const scopeValue = item[`emission_scope${i}`];

          if ($_helper_isNumberType(scopeValue)) {
            $_helper_isNegativeNumber(scopeValue)
              ? (negativeTotal = formatBigNumber(
                  math.evaluate(
                    `${math.bignumber(formatValue(negativeTotal))} + ${math.bignumber(
                      formatValue(scopeValue),
                    )}`,
                  ), 50
                ))
              : (positiveTotal = formatBigNumber(
                  math.evaluate(
                    `${math.bignumber(formatValue(positiveTotal))} + ${math.bignumber(
                      formatValue(scopeValue),
                    )}`,
                  ), 50
                ));
          }
        }
        return [negativeTotal, positiveTotal];
      });

      let index = -1;
      // year tab
      const oldSelectedYear = this.$store.state.pcaf.dashboard?.trendIndustryChart?.selectedYear;
      index = this.chartData.findIndex((item) => {
        return item.year === oldSelectedYear;
      });
      const listIndex = this.chartData.length - 1;
      if (index < 0) {
        index = listIndex;
      }

      if (index >= 0) {
        if (index < this.maxChartItem) {
          this.indexAtFirstBar = index;
          if (listIndex - index < this.maxChartItem) {
            this.indexAtFirstBar = listIndex - this.maxChartItem + 1;
          }
        } else if (index >= listIndex - 5) {
          // index in last 3 elements
          this.indexAtFirstBar = index === listIndex - 5 ? listIndex - 7 : listIndex - 5;
        } else {
          this.indexAtFirstBar = index;
        }
      } else {
        this.indexAtFirstBar = 0;
      }
      [this.axisYItemsSource, this.min, this.max] = prepareChartAxisY(summary, AXIS_Y_ITEM_COUNT, this.unit);
      this.prepareChartDataValue();
    },
    getMaxChart() {
      const maximumColumnsToShow = MAX_CHART_ITEMS;
      if (window.innerWidth < MOBILE_WIDTH) {
        this.maxChartItem = maximumColumnsToShow;
        return;
      }
      const props = {
        maximumColumnsToShow,
        processedData: this.chartData,
        indexAtFirstBar: this.indexAtFirstBar,
        axisYItemsSource: this.axisYItemsSource,
        chartContainerRef: this.$refs.chartPcaf4,
        MOBILE_WIDTH,
        getSummary: this.getSummary,
        axisYItemsCount: AXIS_Y_ITEM_COUNT
      }
      this.maxChartItem = getMaxChartItemsCount(props);
    },
    selectedFirstChartItem() {
      if (!this.chartData.length) {
        this.$emit('updateSelectedChartData', {}, window.innerWidth < TABLET_WIDTH, false);
        return;
      }

      let index = -1;

      // year tab
      const oldSelectedYear = this.$store.state.pcaf.dashboard?.trendIndustryChart?.selectedYear;
      index = this.chartData.findIndex((item) => {
        return item?.year === oldSelectedYear;
      });
      if (index < 0) {
        index = this.chartData.length - 1;
      }

      if (index >= 0) {
        const item = this.chartData[index];
        this.selectedTimeRange = item?.time_range;
        this.$emit('updateSelectedChartData', item, window.innerWidth < TABLET_WIDTH, false);
      } else {
        const latestDurationIndex = this.chartData.length - 1;
        const item = this.chartData[latestDurationIndex];
        this.selectedTimeRange = item.time_range;
        this.$emit('updateSelectedChartData', item, window.innerWidth < TABLET_WIDTH, false);
      }

      if (this.indexAtFirstBar + this.maxChartItem >= this.chartData.length) {
        this.isDisabledNext = true;
      } else {
        this.isDisabledNext = false;
      }

      if (this.indexAtFirstBar <= 0) {
        this.isDisabledPrev = true;
      } else {
        this.isDisabledPrev = false;
      }
    },
    prepareChartDataValue() {
      // if (!this.chartData.length) {
      //   return;
      // }

      let index = 0;
      let chartValueOriginal = [];
      this.getMaxChart();
      do {
        chartValueOriginal.push(this.chartData[index + this.indexAtFirstBar]);
        index++;
      } while (index < this.maxChartItem);

      chartValueOriginal = chartValueOriginal.filter((item) => !!item);

      this.axisXItemsSource = chartValueOriginal.map((item) => {
        return {
          label: item.time_range,
          value: item.time_range,
        };
      });

      this.totalScopes.forEach((i) => {
        this.chartValue = chartValueOriginal.map((item) => {
          return {
            ...item,
            [`emission_scope${i}_percent`]: '0%',
          };
        });
      });
      const timeout = this.isFirstRender ? 1000 : 0;

      setTimeout(() => {
        const summary = this.getSummary(chartValueOriginal);
        [this.axisYItemsSource, this.min, this.max] = prepareChartAxisY(summary, AXIS_Y_ITEM_COUNT, this.unit);
        this.getWidthOfText();
        this.totalScopes.forEach((i) => {
          this.chartValue = this.chartValue.map((item) => {
            return {
              ...item,
              [`emission_scope${i}_percent`]: this.calcScopeEmission(item[`emission_scope${i}`]),
              zero_value_percent: Math.abs(Number(this.min) / (this.max - this.min)) * 100 + '%',
            };
          });
        });
      }, timeout);

      setTimeout(() => {
        this.transitionWidth = 0;

        this.footerWidth = this.$refs.monthLeft.clientWidth;
      }, timeout + 500);

      if (this.isFirstRender) {
        setTimeout(() => {
          this.isFirstRender = false;
        }, timeout + 500);
      }
    },
    getSummary(chartValueOriginal) {
      const summary = chartValueOriginal.map((item) => {
        let negativeTotal = 0;
        let positiveTotal = 0;
        for (let i = 0; i <= this.totalScopes.length - 1; i++) {
          const scopeValue = item[`emission_scope${i}`];
          if ($_helper_isNumberType(scopeValue)) {
            $_helper_isNegativeNumber(scopeValue)
              ? (negativeTotal = formatBigNumber(
                  math.evaluate(
                    `${math.bignumber(formatValue(negativeTotal))} + ${math.bignumber(
                      formatValue(scopeValue),
                    )}`,
                  ), 50
                ))
              : (positiveTotal = formatBigNumber(
                  math.evaluate(
                    `${math.bignumber(formatValue(positiveTotal))} + ${math.bignumber(
                      formatValue(scopeValue),
                    )}`,
                  ), 50
                ));
          }
        }
        return [negativeTotal, positiveTotal];
      });
      return summary;
    },
    onMouseOver(item) {
      this.hoverTimeRange = item.time_range;
    },
    onMouseLeave() {
      this.hoverTimeRange = '';
    },
    handleMouseOverNextBtn() {
      this.isMouseOverNext = true;
      this.nextIconUrl = '';
    },
    handleTouchStartNextBtn() {
      this.prevIconUrl = '';
      this.nextIconUrl = 'img/icons/next-hover.svg';
    },
    handleTouchEndNextBtn() {
      setTimeout(() => {
        if (this.isDisabledNext) {
          this.nextIconUrl = 'img/icons/next-disabled.svg';
        } else {
          this.nextIconUrl = 'img/icons/next.svg';
        }
      }, 200);
    },
    next() {
      this.prevIconUrl = '';
      if (this.indexAtFirstBar + this.maxChartItem >= this.chartData.length) {
        this.isDisabledNext = true;
        return;
      }

      this.isDisabledNext = false;
      this.indexAtFirstBar = this.indexAtFirstBar + 1;
      this.$store.dispatch('dashboard/changeIndexAtFirstBarByPeriodWithMonthly', this.indexAtFirstBar);
      this.prepareChartDataValue();

      if (this.indexAtFirstBar + this.maxChartItem >= this.chartData.length) {
        this.isDisabledNext = true;
      }

      if (this.indexAtFirstBar > 0) {
        this.isDisabledPrev = false;
      } else {
        this.isDisabledPrev = true;
      }
    },
    handleMouseOverPrevBtn() {
      this.isMouseOverPrev = true;
      this.prevIconUrl = '';
    },
    handleTouchStartPrevBtn() {
      this.nextIconUrl = '';
      this.prevIconUrl = 'img/icons/prev-hover.svg';
    },
    handleTouchEndPrevBtn() {
      setTimeout(() => {
        if (this.isDisabledPrev) {
          this.prevIconUrl = 'img/icons/prev-disabled.svg';
        } else {
          this.prevIconUrl = 'img/icons/prev.svg';
        }
      }, 200);
    },
    prev() {
      this.nextIconUrl = '';
      if (this.indexAtFirstBar <= 0) {
        this.isDisabledPrev = true;
        return;
      }

      this.isDisabledPrev = false;

      this.indexAtFirstBar = this.indexAtFirstBar - 1;
      this.$store.dispatch('dashboard/changeIndexAtFirstBarByPeriodWithMonthly', this.indexAtFirstBar);
      this.prepareChartDataValue();

      if (this.indexAtFirstBar <= 0) {
        this.isDisabledPrev = true;
      }

      if (this.indexAtFirstBar + this.maxChartItem < this.chartData.length) {
        this.isDisabledNext = false;
      }
    },
    isPositiveNumber(item) {
      return item.emission_scope1 >= 0 && item.emission_scope2 >= 0 && item.emission_scope3 >= 0;
    },
    calcScopeEmission(emissionsValue) {
      if (emissionsValue < 0) {
        return Number(formatBigNumber(Math.abs((Number(emissionsValue) / (this.min))))) * 100 + '%';
      } else {
        return Number(formatBigNumber(Math.abs((Number(emissionsValue) / (this.max))))) * 100 + '%';
      }
    },
    getWidthOfText() {
      this.minWidth = getMinWidthYAxisChartColumn(this.axisYItemsSource);
    },
    handleGetValueChart(chartItem) {
      this.selectedTimeRange = chartItem.time_range;
      this.updateTrendIndustryChart({
        ...this.dashboard.trendIndustryChart,
        selectedYear: chartItem?.year,
      });
      this.$emit('updateSelectedChartData', chartItem, window.innerWidth < TABLET_WIDTH, true);
    },
    prepareDataByType(item) {
      return isTotalEmission(this.type) ? item?.total_emission : item?.total_amount;
    },
  },
};
</script>
