<template>
  <div class="container-breakdown">
    <div class="header">{{ $t('dashboard_main.label_breakdown') }}</div>
    <div class="content">
      <div class="content-block" v-for="(item, index) in data" :key="index">
        <div class="title-block">{{ convertMethodName(item.item_name) }}</div>
        <div class="descipt-block">
          {{ getDetailType(item) }}
          <span class="unit" style="display: inline-block">{{ getUnit }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatBigNumber, math, formatValue } from '@/concerns/newRegisterData/wijmo.helper';
import { formatNumberByConditions } from '@/utils/convertNumber';
import { UNIT_SETTING } from '@/constants/dashboard';
import { getUnit } from '@/utils/getTextWithCondition';
import { isTotalEmission } from '@/utils/pcaf';
import { GICS_PULLDOWN } from '@/constants/registerData';
import { mapState } from 'vuex';
import { formatValueUnit } from '@/concerns/chart';
export default {
  props: {
    dataChartDetail: {
      type: Object,
      default: {},
    },
    type: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    currency: {
      type: Number,
      default: 1,
    }
  },
  mounted() {
    this.getDataDetailChart();
  },
  computed: {
    ...mapState('settingsDashboard', ['itemsSettings']),

    getUnitState() {
      return this.itemsSettings[this.$store.state.userData.contractor]?.unitName || 't-CO2';
    },
    getUnit() {
      return getUnit(this.type, this.currency, this.getUnitState);
    },
  },
  data() {
    return {
      data: [],
    };
  },
  methods: {
    getDataDetailChart() {
      if (this.dataChartDetail.detail && this.dataChartDetail.detail.length > 0) {
        this.data = {...this.dataChartDetail.detail};
      }
    },
    getDetailType(item) {
      let totalValue = 0;
      if (isTotalEmission(this.type)) {
        const isTco2Unit = this.getUnitState === 't-CO2';
        const value = formatValueUnit(item?.total_emission, isTco2Unit)
        totalValue = formatNumberByConditions(value, { formatUnit: UNIT_SETTING.CO2 });
      } else {
        switch (this.currency) {
          case 1:
            totalValue = formatNumberByConditions(
              formatBigNumber(math.evaluate(`${math.bignumber(formatValue(item?.total_amount)) / 1000000}`), 50),
              { formatUnit: UNIT_SETTING.CURRENCY },
            );
            break;
          case 2:
          case 3:
            totalValue = formatNumberByConditions(
              formatBigNumber(math.evaluate(`${math.bignumber(formatValue(item?.total_amount)) / 1000}`), 50),
              { formatUnit: UNIT_SETTING.CURRENCY },
            );
            break;
          default:
            break;
        }
      }
      return totalValue;
    },
    convertMethodName(name) {
      return GICS_PULLDOWN.find(item => item.key === name)?.value || name;
    }
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.container-breakdown {
  background-color: $monoOffWhite;
  &.chart-popup {
    background-color: #ffffff;
  }
  .header {
    padding: 8px 16px;
    border-bottom: 1px solid $monoDusty;
    // font-family: Source Han Sans JP;
    font-size: 11px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.03em;
    color: $monoDark;
  }
  .content-block {
    padding: 8px 16px;
    .title-block {
      // font-family: Source Han Sans JP;
      font-size: 12px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.05em;
      color: $monoDark;
      word-break: break-word;
    }
  }
  .descipt-block {
    font-family: Century Gothic Pro;
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.05em;
    color: $monoBlack;
    word-break: break-all;
    .unit {
      font-family: "Source Han Sans";
    }
  }
  .unit {
    // font-family: Source Han Sans JP;
    font-size: 11px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: $monoBlack;
  }
}
</style>
