<template>
  <div class="chart-container">
    <div class="assets-type-list">
      <div class="assets-type-item" v-for="(item, index) in assetsTypes" :key="index">
        <span class="assets-point" :style="{ backgroundColor: item.borderColor }"></span>
        <span class="assets-title">{{ item.item_name }}</span>
      </div>
    </div>
    <div v-if="isInvestment" class="chart-handler">
      <div
        class="chart-handler-action prev"
        :class="isDisabledPrev && 'disable'"
        @click="handlePrev"
        @mouseover="isMouseOverPrev = true"
        @mouseleave="isMouseOverPrev = false"
        @mousedown="isBlurPrev = true"
        @mouseup="isBlurPrev = false"
      >
        <img :src="prevIcon" alt="Prev" />
      </div>
      <div class="chart-handler_label">{{ selectedRangeConvert }}</div>
      <div
        class="chart-handler-action next"
        :class="isDisabledNext && 'disable'"
        @click="handleNext"
        @mouseover="isMouseOverNext = true"
        @mouseleave="isMouseOverNext = false"
        @mousedown="isBlurNext = true"
        @mouseup="isBlurNext = false"
      >
        <img :src="nextIcon" alt="Next" />
      </div>
    </div>
    <div class="chart-wrap" :class="{'has-button': isInvestment}">
      <div class="pie-chart-container chart-data" >
        <wj-flex-pie class="display-border pie-chart" :itemsSource="data" :initialized="initialize">
          <wj-flex-pie-data-label :content="getLabelContent"></wj-flex-pie-data-label>
        </wj-flex-pie>
      </div>
    </div>
  </div>
</template>
<script>
import '@mescius/wijmo.styles/wijmo.css';
import '@mescius/wijmo.vue2.chart';
import { TABLET_WIDTH } from '@/constants/screen-size';
import { isTotalEmission } from '@/utils/pcaf';
import i18n from '@/lang/i18n.js';
import { formatNumberByConditions } from '@/utils/convertNumber';
import { UNIT_SETTING } from '@/constants/dashboard';
export default {
  props: {
    chartData: {
      type: Object,
      default: () => {},
    },
    type: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    selectedRange: {
      type: String,
      default: null,
    },
    isDisabledNext: {
      type: Boolean,
      default: true,
    },
    isDisabledPrev: {
      type: Boolean,
      default: true,
    },
    generateColor: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      data: [],
      assetsTypes: [],
      key: 0,
      isMouseOverPrev: false,
      isMouseOverNext: false,
      isBlurPrev: false,
      isBlurNext: false,
      flexChart: null,
    };
  },
  mounted() {
    this.convertData();
  },
  methods: {
    convertData() {
      this.assetsTypes=[]
      const tmp = this.chartData?.data;
      if (!tmp) return;
      tmp?.forEach((item, index) => {
        this.assetsTypes.push({
          ...item,
          item_name: item?.item_name || '',
          borderColor: this.generateColor[index],        
        })
        if (item.total_amount_pattern === '0' || item.total_amount_pattern === null || item.total_emission_pattern === '0' || item.total_emission_pattern === null) {
          return
        }
        this.data.push({
          ...item,
          item_name: item?.item_name || '',
          borderColor: this.generateColor[index],
        })
      });
    },
    initialize(flexChart) {
      this.flexChart = flexChart;
      flexChart.dataLabel.position = 'Outside';
      flexChart.dataLabel.offset = 20;
      flexChart.dataLabel.connectingLine = true;
      flexChart.legend.position = 'None';
      flexChart.tooltip.content = '';
      flexChart.startAngle = 90;
      flexChart.bindingName = 'item_name';
      flexChart.binding = isTotalEmission(this.type) ? 'total_emission_pattern' : 'total_amount_pattern';

      flexChart.itemFormatter = (engine, hitTestInfo, defaultRenderer) => {
        const fill = this.data.find((color) => color.item_name === hitTestInfo.name)?.borderColor;
        // Set the fill color for each slice
        engine.fill = fill;
        if (this.data.length === 1) {
          engine.stroke = fill;
        } else {
          engine.stroke = '#ffffff';
        }
        
        // Render the slice using the default renderer
        defaultRenderer();
      };
      flexChart.onRendered = () => {
        const lines = document.getElementsByClassName('wj-data-label-line');
        const texts = document.getElementsByClassName('wj-data-label');
        for (var i = 0; i < lines.length; i++) {
          var path = lines[i];
          path.style.stroke = this.data.find((color) => (isTotalEmission(this.type) ? color.percent_emission_pt : color.percent_amount_pt) === texts[i].textContent.split("%")[0])?.borderColor;
        }
      };
      flexChart.hostElement.addEventListener('click', (e) => {
        if (window.innerWidth > TABLET_WIDTH) return
        this.$emit('openChartDetail');
      });
    },
    getLabelContent(ht) {
      const percent = isTotalEmission(this.type) ? ht.item.percent_emission_pt : ht.item.percent_amount_pt;
      const formatPercent = formatNumberByConditions(percent, { formatUnit: UNIT_SETTING.PERCENT })
      if (window.innerWidth > TABLET_WIDTH) {
        return `<tspan style="font-size: 11px;font-style: normal;font-weight: 700;line-height: 18px;letter-spacing: 0.33px;" fill="#404D50">${formatPercent}%</tspan>`;
      }
      return `<tspan style="font-size: 9px;font-style: normal;font-weight: 700;line-height: 18px;letter-spacing: 0.33px;" fill="#404D50">${formatPercent}%</tspan>`;
    },
    handlePrev() {
      // handle prev by tab
      this.$emit('handleButton', 'prev');
    },
    handleNext() {
      // handle next by tab
      this.$emit('handleButton', 'next');
    },
  },
  computed: {
    selectedRangeConvert() {
      if (!this.selectedRange) return;
      if (this.tab === 0) {
        return this.selectedRange;
      } else if (this.tab === 1) {
        const parts = this.selectedRange.split('-');
        const order = parts[0];
        const year = parts[1];
        const formatted = Number(order) === 1 ? this.$t("dashboard_main.label_first_half_of_fiscal_year") : this.$t("dashboard_main.label_end_half_value");
        return i18n.locale === 'vi' ? `${formatted} ${year}` : `${year} ${formatted}`;
      } else if (this.tab === 2) {
        const parts = this.selectedRange.split('-');
        const order = parts[0];
        const year = parts[1];
        return `${year} ${order}Q`;
      } else if (this.tab === 3) {
        return this.selectedRange?.replace('-','/');
      }
    },
    nextIcon() {
      if (this.isDisabledNext) {
        this.isBlurNext = false;
        this.isMouseOverNext = false;
        return 'img/icons/next-disabled.svg';
      }

      if (this.isBlurNext) {
        return 'img/icons/next.svg';
      }

      if (this.isMouseOverNext) {
        return 'img/icons/next-hover.svg';
      }

      return 'img/icons/next.svg';
    },
    prevIcon() {
      if (this.isDisabledPrev) {
        this.isBlurPrev = false;
        this.isMouseOverPrev = false;
        return 'img/icons/prev-disabled.svg';
      }

      if (this.isBlurPrev) {
        return 'img/icons/prev.svg';
      }

      if (this.isMouseOverPrev) {
        return 'img/icons/prev-hover.svg';
      }

      return 'img/icons/prev.svg';
    },
    isInvestment() {
      return [2].includes(this.type);
    }
  },
};
</script>
<style lang="scss" scoped>
.chart-container {
  width: 100%;
  padding: 16px 16px 16px;
}

.assets-type-list {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 16px;
  .assets-type-item {
    margin-right: 8px;
    line-height: 14px;
  }
  .assets-point {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 8px;
  }
  .assets-title {
    font-size: 11px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.03em;
    text-align: left;
    color: rgba(64, 77, 80, 1);
  }
}
.display-border {
  border: none;
}

.chart-handler {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 40px;
  margin-bottom: 16px;
  &_label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.42px;
    color: $monoBlack;
  }
  &-action {
    min-width: 36px;
    height: 100%;
    border-radius: 4px;
    display: flex;
    padding: 7px 8px 9px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    background: $monoOffWhite;
    box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24),
      0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17),
      0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13),
      0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11),
      0px 36px 33px 0px rgba(160, 181, 186, 0.07);
    &:hover {
      background: $goldMid;
    }
    &.disable {
      background: $monoLight;
      cursor: text;
      &:hover {
        background: $monoLight;
      }
    }
  }
}

@include desktop {
  .chart-handler {
    display: flex;
  }
  ::v-deep .wj-data-label {
    display: flex;
  }
  .chart-wrap {
    margin-top: 174px;
    &.has-button {
      margin-top: 144px;
    }
  }
  .pie-chart-container {
    .wj-flexchart {
      height: 485px;
    }
  }
  .pie-chart-container {
    &.chart-data {
      width: 100%;
      height: 485px;
    }
  }
  .chart-wrap {
    width: 100%;
    height: 485px;
    overflow-x: auto;
    overflow-y: hidden;
  }
}
@include large-desktop {
  .chart-container {
    width: calc(100% - 327px - 16px);
    margin-right: 16px;
  }
}

@media (min-width: 575px) {
  .pie-chart-container {
    &.chart-data {
      width: auto;
      height: 540px;
    }
  }
  .chart-wrap {
    width: 100%;
    height: 540px;
    overflow-x: auto;
    overflow-y: hidden;
  }
}

@media (max-width: 575px) {
  .pie-chart-container {
    &.chart-data {
      width: 680px;
      height: 540px;
    }
  }
  .chart-wrap {
    width: 100%;
    height: 400px;
    overflow-x: auto;
    overflow-y: hidden;
  }
}

.chart-wrap::-webkit-scrollbar { 
  display: none;
}
</style>