<template>
  <div class="chart-detail-wrapper">
    <div class="category-detail">
      <div class="category-info mt-0">
        <div class="category-title">{{ $t('dashboard_main.label_category') }}</div>
        <div class="scope-name">{{ scopeName }}</div>
        <div class="category-name">
          {{ getCategoryNumber }}
          {{ getTitleScope }}
        </div>
      </div>
      <!-- progress chart -->
      <div class="percent-total-emission" :style="getStylePercentTotalEmission">
        <div class="category-summary">
          <div class="category-title">{{ titleTotalEmission }}</div>
          <div class="content">
            <span class="value">{{
              calculateTop5Emissions(
                emissionsByCategoryDetail.value
                  ? emissionsByCategoryDetail.value
                  : emissionsByCategoryDetail.total_emission,
              )
            }}</span>
            <span class="unit">{{ getUnit }}</span>
          </div>
        </div>
        <div class="compare-with-all" v-if="!isGetAllFilter">
          <div class="category-title">{{ $t('dashboard_main.percent_total_emissions_compare_all_branches') }}</div>

          <div class="content ratio-values">
            <div class="icon">
              <v-progress-circular
                :value="getPercentTotalEmission ? getPercentTotalEmission : 0"
                color="#A9A04B"
                size="28"
                width="2"
                rotate="270"
              />
            </div>
            <div class="value">{{ getPercentTotalEmission}}</div>
            <div class="unit" v-show="getPercentTotalEmission">%</div>
            <div class="next" @click="goToDetail('全拠点比')" @mouseover="right1 = true" @mouseleave="right1 = false">
              <img v-if="!right1" width="18" src="img/icons/right.svg" alt="Right" />
              <img v-else width="18" src="img/icons/rightHover.svg" alt="Right" />
            </div>
          </div>
        </div>
      </div>
      <div class="compare-parent">
        <div class="category-compare">
          <div class="category-title">{{ getCategoryTitle }}</div>
          <div class="content ratio-values">
            <span class="value">{{ getRatioCategory('rate_last_same') }}</span>
            <span class="unit" v-show="getRatioCategory('rate_last_same') !== '-'">%</span>
            <span class="up" v-if="getRatioCategory('rate_last_same') !== '-'">
              <img :src="status.src" :alt="status.alt" />
            </span>
          </div>
        </div>
        <!-- compare ratio value -->
        <div class="category-compare" v-if="getStatusCompare">
          <div class="category-title">{{ getTitleCategoryCompare }}</div>
          <div class="content ratio-values">
            <span class="value">{{ getRatioCategory('rate_last') }}</span>
            <span class="unit" v-show="getRatioCategory('rate_last') !== '-'">%</span>
            <span class="up" v-if="getRatioCategory('rate_last') !== '-'">
              <img :src="statusCompare.src" :alt="statusCompare.alt" />
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="emissionsByCategoryDetail.scopeName && !emissionsByCategoryDetail.indexCredit" class="item-high-emission">
      <div class="item-high-emission__title">
        {{
          emissionsByCategoryDetail.scopeName === 'scope4'
            ? $t('dashboard_main.label_items_with_high_emissions_scope4')
            : $t('dashboard_main.label_items_with_high_emissions')
        }}
      </div>

      <div class="items">
        <div class="item" v-for="(item, index) in emissionsByCategoryDetailTop5" :key="index">
          <div class="item__title">
            {{ emissionsByCategoryDetail.scopeName === 'scope4' ? `${item.cert_standards} - ` : '' }} {{ item.name }}
          </div>

          <div class="content">
            <div class="data">
              <span class="value">{{ calculateTop5Emissions(item.value) }}</span>
              <span style="display: inline-block" class="unit">{{ getUnit }}</span>
            </div>

            <div class="compare">
              <div class="icon">
                <v-progress-circular
                  :value="item.rate_all ? item.rate_all : 0"
                  color="#A9A04B"
                  size="22"
                  width="2"
                  rotate="270"
                  class="mr-0"
                ></v-progress-circular>
              </div>

              <div class="value mr-2">{{ formatPercent(item.rate_all) }}</div>
              <div class="unit" v-show="item.rate_all !== null && item.rate_all !== undefined">%</div>
              <div
                class="right"
                @click="
                  goToDetail(
                    emissionsByCategoryDetail.scopeName === 'scope4'
                      ? `${item.cert_standards} - ${item.name}`
                      : item.name,
                    item.type_credit_id,
                  )
                "
                @mouseover="rightMouseOver(index)"
                @mouseleave="rightMouseLeave()"
              >
                <img v-if="index !== rightIndex" width="18" src="img/icons/right.svg" alt="Right" />
                <img v-else width="18" src="img/icons/rightHover.svg" alt="Right" />
              </div>
            </div>
          </div>
        </div>
        <div v-if="emissionsByCategoryDetailTop5.length >= 3 && emissionsByCategoryDetailTop5.length % 2 != 0" class="item bottom-none">
        </div>
      </div>

    </div>
    <div class="link-to-register" v-if="!isDialog">
      <router-link v-if="isShowButton" :to="{ path: getUrlToRegisterData.path, query: getUrlToRegisterData.query }" :class="{ disabled: Object.keys(emissionsByCategoryDetail).length === 0 }"
        ><common-button class="mt-4 common-btn" type="colored" :label="textBtnDetail" :disabled="Object.keys(emissionsByCategoryDetail).length === 0"></common-button
      ></router-link>
    </div>
  </div>
</template>

<style lang="scss">
@import 'mobile';
@import 'desktop';
</style>

<script>
import { mapState, mapActions } from 'vuex';
import { ROUTES } from '@/router/constants';
import { getTitle, getTitleCompare, getRatioByCategory, chartTitleMonthYear } from '@/utils/getTextWithCondition';
import webStorage from '@/utils/webStorage';
import CommonButton from '@/components/utils/button.vue';
import { formatNumberByConditions } from '@/utils/convertNumber';
import { PERIOD_DASHBOARD, UNIT_SETTING } from "@/constants/dashboard";
import {
  formatValue,
  math,
  formatBigNumber,
} from '@/concerns/newRegisterData/wijmo.helper';
export default {
  components: { CommonButton },
  data() {
    return {
      right1: false,
      rightIndex: -1,
    };
  },
  props: {
    emissionsByCategoryDetail: {
      type: Object,
      default: () => {},
    },
    emissionsByCategoryDetailTop5: {
      type: Array,
      default: () => [],
    },
    selectedTab: {
      type: Number,
      default: 0,
    },
    emissionsByCategoryCompareDetail: {
      type: Object,
      default: () => {},
    },
    branchIds: {
      type: Array,
      default: () => [],
    },
    isGetAllFilter: {
      type: Boolean,
      default: true,
    },
    isShowButton: {
      type: Boolean,
      default: true,
    },
    isDialog: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    emissionsByCategoryDetail() {},
    emissionsByCategoryCompareDetail() {},
  },
  mounted() {
    this.setPath(this.getUrlToRegisterData.path);
    this.setQuery(this.getUrlToRegisterData.query);
  },
  computed: {
    ...mapState('dashboard', ['selectedEmissionsByCategoryDashboard']),
    ...mapState('settingsDashboard', ['itemsSettings']),
    getUnit() {
      return this.itemsSettings[this.$store.state.userData.contractor]?.unitName || 't-CO2';
    },
    getStylePercentTotalEmission() {
      return {
        flexDirection: this.isDialog ? 'column' : '',
        gap: this.isDialog ? '0' : '',
      };
    },
    textBtnDetail() {
      return this.$t('dashboard_main.button_view_detail');
    },
    getPercentTotalEmission() {
      const ratioData = getRatioByCategory(this.selectedTab, this.emissionsByCategoryDetail?.detail_ratio);
      let ratioValue = ratioData ? ratioData.rate_all : '0.0';
      return !ratioValue ? '-' : formatNumberByConditions(ratioValue, { formatUnit: UNIT_SETTING.PERCENT });
    },
    getCategoryNumber() {
      if (this.emissionsByCategoryDetail.scopeName !== 'scope3') return;
      return this.emissionsByCategoryDetail.category ? this.emissionsByCategoryDetail.category + '. ' : '';
    },
    totalValue() {
      return (number) => {
        return +!!number != 0 ? formatNumberByConditions(number, { formatUnit: UNIT_SETTING.CO2 }) : '-';
      };
    },
    isHasBranchIds() {
      return this.branchIds.length > 0;
    },
    getStatusCompare() {
      let statusCompare = false;
      switch (this.selectedTab) {
        case 0: // year
          statusCompare = false;
          break;
        case 1: // half
          statusCompare = this.selectedEmissionsByCategoryDashboard.checkBoxHalfYearStatus;
          break;
        case 2: // quarter
          statusCompare = this.selectedEmissionsByCategoryDashboard.checkBoxQuarterYearStatus;
          break;
        case 3: // month year
          statusCompare = this.selectedEmissionsByCategoryDashboard.checkBoxMonthYearStatus;
          break;
        default:
          break;
      }
      return statusCompare;
    },
    getCategoryTitle() {
      return getTitle(this.selectedTab);
    },
    getTitleCategoryCompare() {
      return getTitleCompare(this.selectedTab);
    },
    getRatioCategory() {
      return (type) => {
        if (this.selectedTab === 0) {
          type = 'rate_last';
        }
        const ratioData = getRatioByCategory(this.selectedTab, this.emissionsByCategoryDetail?.detail_ratio);
        let ratioValue = ratioData ? ratioData[type] : '0.0';
        return !ratioValue ? '-' : formatNumberByConditions(ratioValue, { formatUnit: UNIT_SETTING.PERCENT });
      };
    },
    getTitleScope() {
      let title = this.emissionsByCategoryDetail.title;
      if (!this.emissionsByCategoryDetail.title && this.emissionsByCategoryDetail.name) {
        title = `${this.emissionsByCategoryDetail.name} - ${this.emissionsByCategoryDetail.type}`;
      }
      return title;
    },
    scopeName() {
      switch (this.emissionsByCategoryDetail.scopeName) {
        case 'scope1':
          return `${this.$t('dashboard_main.label_scope')} 1`;
        case 'scope2':
          return `${this.$t('dashboard_main.label_scope')} 2`;
        case 'scope3':
          return `${this.$t('dashboard_main.label_scope')} 3`;
        case 'scope4':
          return this.$t('list_emissions.title_scope_others');
        default:
          return '';
      }
    },
    statusCompare() {
      let ratioUp = getRatioByCategory(this.selectedTab, this.emissionsByCategoryDetail?.detail_ratio)?.up;

      if (ratioUp === 1) {
        return {
          src: 'img/icons/up.svg',
          alt: 'Up',
        };
      } else {
        return {
          src: 'img/icons/down.svg',
          alt: 'Down',
        };
      }
    },
    titleTotalEmission() {
      let selectedRangeData = {};
      switch (this.selectedTab) {
        case 0:
          const oldYearSelected = this.selectedEmissionsByCategoryDashboard.selectedYear;
          selectedRangeData['year'] = oldYearSelected?.year;
          break;
        case 1:
          const oldHalfYearSelected = this.selectedEmissionsByCategoryDashboard.selectedHalfYear;
          selectedRangeData['year'] = oldHalfYearSelected?.year;
          selectedRangeData['order'] = oldHalfYearSelected?.order;
          break;
        case 2:
          const oldQuarterYearSelected = this.selectedEmissionsByCategoryDashboard.selectedQuarterYear;
          selectedRangeData['year'] = oldQuarterYearSelected?.year;
          selectedRangeData['order'] = oldQuarterYearSelected?.order;
          break;
        case 3:
          const oldMonthYearSelected = this.selectedEmissionsByCategoryDashboard.selectedMonthYear;
          const [oldYear, oldMonth] = oldMonthYearSelected?.selected_range?.split('/');
          selectedRangeData['year'] = oldYear;
          selectedRangeData['month'] = parseInt(oldMonth);
        default:
          break;
      }
      let label =
        this.scopeName === this.$t('list_menu.title_scope_others')
          ? this.$t('dashboard_main.label_total_emissions_scope4')
          : this.$t('dashboard_main.label_total_emissions');
      const title = chartTitleMonthYear(
        this.selectedTab,
        selectedRangeData?.year,
        selectedRangeData?.order,
        selectedRangeData?.month,
        label,
      );

      return title;
    },
    status() {
      let ratioUp = getRatioByCategory(this.selectedTab, this.emissionsByCategoryDetail?.detail_ratio);
      ratioUp = this.selectedTab === 0 ? ratioUp?.up : ratioUp?.up_same;
      if (ratioUp === 1) {
        return {
          src: 'img/icons/up.svg',
          alt: 'Up',
        };
      } else {
        return {
          src: 'img/icons/down.svg',
          alt: 'Down',
        };
      }
    },
    getUrlToRegisterData() {
      const [scope, category, duration] = this.getCategoryScopeOfStore();
      let path =
        ROUTES.EMISSIONS +
        '/view' +
        '/' +
        (scope === undefined ? '1' : scope) +
        '/' +
        (category === undefined ? '1' : category);
      let query = {
        ...duration,
      };

      return { path, query };
    },
    canRedirectToRegisterData() {
      return this.$store.state.dashboard.queryParams.duration_id;
    }
  },
  methods: {
    ...mapActions('emission', ['setQuery', 'setPath']),
    getCategoryScopeOfStore() {
      let scope = '';
      let category = this.emissionsByCategoryDetail.category;
      let duration = {};
      switch (this.selectedTab) {
        case 0:
          scope = this.selectedEmissionsByCategoryDashboard.selectedYearDetail?.scope;
          duration.year = this.selectedEmissionsByCategoryDashboard.selectedYear?.year;
          duration.period = PERIOD_DASHBOARD.PERIOD_YEAR
          break;
        case 1:
          scope = this.selectedEmissionsByCategoryDashboard.selectedHalfYearDetail?.scope;
          const oldHalfYear = this.selectedEmissionsByCategoryDashboard?.selectedHalfYear;
          duration.year = oldHalfYear?.year;
          duration.order = oldHalfYear?.order;
          duration.period = PERIOD_DASHBOARD.PERIOD_HALF_YEAR
          break;
        case 2:
          scope = this.selectedEmissionsByCategoryDashboard.selectedQuarterYearDetail?.scope;
          const oldQuarterYear = this.selectedEmissionsByCategoryDashboard?.selectedQuarterYear;
          duration.year = oldQuarterYear?.year;
          duration.order = oldQuarterYear?.order;
          duration.period = PERIOD_DASHBOARD.PERIOD_QUARTER_YEAR
          break;
        case 3:
          scope = this.selectedEmissionsByCategoryDashboard.selectedMonthYearDetail?.scope;
          const oldMonthYearSelected = this.selectedEmissionsByCategoryDashboard?.selectedMonthYear;
          const [oldYear, oldMonth] = oldMonthYearSelected?.selected_range?.split('/');
          duration.year = oldYear;
          duration.month = oldMonth;
          duration.period = PERIOD_DASHBOARD.PERIOD_MONTH_YEAR
          break;
        default:
          break;
      }
      return [scope, category, duration];
    },
    goToDetail(title, type_credit_id) {
      const up = this.selectedTab === 0 ? this.emissionsByCategoryDetail?.up : this.emissionsByCategoryDetail?.up_same;
      const categoryName = this.emissionsByCategoryDetail?.title;
      const [scope, category] = this.getCategoryScopeOfStore();

      let query = {
        scope: scope,
        type: this.selectedTab === 0 ? 1 : this.selectedTab === 3 ? 2 : this.selectedTab + 2,
        categoryName,
        category: category,
        up,
        type_credit_id: type_credit_id,
      };
      switch (this.selectedTab) {
        case 0:
          query = {
            ...query,
            year: this.selectedEmissionsByCategoryDashboard.selectedYear.year,
          };
          break;
        case 1:
          query = {
            ...query,
            year: this.selectedEmissionsByCategoryDashboard.selectedYear.year,
            order: this.selectedEmissionsByCategoryDashboard.selectedHalfYear.order,
          };
          break;
        case 2:
          query = {
            ...query,
            year: this.selectedEmissionsByCategoryDashboard.selectedQuarterYear.year,
            order: this.selectedEmissionsByCategoryDashboard.selectedQuarterYear.order,
          };
          break;
        default:
          query = {
            ...query,
            year: this.selectedEmissionsByCategoryDashboard.selectedMonthYear?.selected_range?.split('/')[0],
            month: this.selectedEmissionsByCategoryDashboard.selectedMonthYear?.selected_range?.split('/')[1],
          };
          break;
      }
      webStorage.set('title', title);
      // return
      this.$router.push({
        path: ROUTES.EMISSIONS + '/' + ROUTES.EMISSIONS_BY_BASE_CATEGORY,
        query: query,
      });
    },
    goToRegister() {
      const scope = this.$store.state.dashboard.selectedEmissionsByCategoryWithYearly.scope;
      const category = this.$store.state.dashboard.selectedEmissionsByCategoryWithYearly.category;
      const duration = this.$store.state.dashboard.queryParams.duration_id;

      this.$router.push({
        path:
          ROUTES.EMISSIONS +
          '/' +
          ROUTES.REGISTER +
          '/' +
          (scope === undefined ? '1' : scope) +
          '/' +
          (category === undefined ? '1' : category),
        query: {
          duration,
        },
      });
    },
    rightMouseOver(value) {
      this.rightIndex = value;
    },

    rightMouseLeave() {
      this.rightIndex = -1;
    },
    calculateTop5Emissions(value) {
      const isTco2Unit = this.getUnit === 't-CO2';
      const valueEmission = math.bignumber(formatValue(value));
      
      const finalValue = isTco2Unit 
          ? value 
          : formatBigNumber(math.multiply(valueEmission, 1000), 50);

      return this.totalValue(finalValue);
    },
    formatPercent(value) {
      return value === null || value === undefined ? '-' : formatNumberByConditions(value, { formatUnit: UNIT_SETTING.PERCENT });
    }

  },
};
</script>
