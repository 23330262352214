<template>
  <div>
    <wj-flex-pie
      class="chart-wrapper"
      :class="{ 'chart-wrapper-dialog': isDialog }"
      header=""
      bindingName="brand"
      binding="sales"
      :itemsSource="scopeData"
      :palette="[scope1Color, scope2Color, scope3Color]"
      :startAngle="90"
      :tooltipContent="tooltipContent"
    >
      <wj-flex-pie-data-label :content="getLabelContent"></wj-flex-pie-data-label>
      <wj-flex-chart-legend :position="'None'"></wj-flex-chart-legend>
    </wj-flex-pie>
  </div>
</template>

<style lang="scss" scoped>
.chart-wrapper {
  &.wj-flexchart {
    border: 0;
    width: 124px;
    height: 124px;
    .wj-data-label {
      fill: #fff;
    }
  }
}

@include tablet {
  .chart-wrapper {
    &.wj-flexchart {
      width: 224px;
      height: 224px;
    }
  }
}
</style>

<style lang="scss">
.chart-wrapper {
  &.wj-flexchart {
    &.chart-wrapper-dialog {
      .wj-data-label {
        font-size: 10px;
      }
    }
    .wj-data-label {
      fill: #fff;
      font-size: 16px;
      line-height: 23.17px;
      font-weight: 500;
    }
  }
}
</style>
<script>
import '@mescius/wijmo.styles/wijmo.css';
import '@mescius/wijmo.vue2.chart';
import { SCOPE_1_COLOR, SCOPE_2_COLOR, SCOPE_3_COLOR } from '../../../chart-color';
import { formatNumberByConditions } from '@/utils/convertNumber';
import { UNIT_SETTING } from '@/constants/dashboard';
const getData = () => {
  return [
    { brand: `${this.$t('dashboard_main.label_scope')} 1`, sales: 0 },
    { brand: `${this.$t('dashboard_main.label_scope')} 2`, sales: 0 },
    { brand: `${this.$t('dashboard_main.label_scope')} 3`, sales: 0 },
  ];
};

export default {
  props: {
    scopeData: {
      type: Array,
      default: getData,
    },
    isDialog: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    scope1Color() {
      return this.scopeData[0]?.sales < 0 ? '#404D50' : SCOPE_1_COLOR;
    },
    scope2Color() {
      return this.scopeData[1]?.sales < 0 ? '#99A6A9' : SCOPE_2_COLOR;
    },
    scope3Color() {
      return this.scopeData[2]?.sales < 0 ? '#7C898C' : SCOPE_3_COLOR;
    }
  },
  watch: {
  },
  methods: {
    getLabelContent(ht) {
      return `${this.formatPercent(ht.value)} ${this.isDialog ? '' : '%'}`;
    },
    tooltipContent(hti) {
      let item = hti.item;
      return `<b>${item.brand}</b><br>${this.formatPercent(item.sales)} %`;
    },
    formatPercent(value) {
      return formatNumberByConditions(value, { formatUnit: UNIT_SETTING.PERCENT })
    }
  },
};
</script>
