<template>
  <div class="chart-details" :class="{'chart-popup': isPopup }">
    <div class="chart-details-duration">
      <div class="chart-details-duration__label">{{ getChartTitle }}</div>
    </div>

    <div class="chart-details-content">
      <div class="chart-details-content_emission">
        <div class="chart-details-content_emission__value">
          {{ getValueEmissions }}
          <span class="chart-details-content_emission__value--unit">{{unit}}</span>
        </div>
      </div>

      <div class="chart-details-content_variation">
        <div class="chart-details-content_variation__label">{{ $t('dashboard_main.label_year_on_year_ratio') }}</div>
        <div class="chart-details-content_variation__content">
          <span class="chart-details-content_variation__content--value">{{
            formatNumber(getRatio, { type: 'chart-detail-ratio', formatUnit: unitSettings.PERCENT})
          }}</span>
          <span v-if="getRatio && getRatio !== '-'" class="chart-details-content_variation__content--percent">%</span>
          <img v-if="getRatio && getRatio !== '-'" :src="imgSrc" alt=""  class="chart-details-content_variation__content--icon"/>
        </div>
      </div>

      <div class="chart-detail-statistic-assets">
        <div class="chart-detail-statistic-assets-item" v-for="(item, index) in trendTotalAssets" :key="index">
          <div style="line-height: 14px;">
            <span class="dot-color" :style="{ backgroundColor: item.borderColor }"></span>
            <span class="name-assets">{{ item.text }}</span>
          </div>
          <div class="chart-detail-statistic-assets__contents">
            <span class="chart-detail-statistic-assets__contents__value">{{ getValueAssets(item.value) }}</span>
            <span class="chart-detail-statistic-assets__contents__unit">{{ unit }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatNumberByConditions } from '@/utils/convertNumber';
import { UNIT_SETTING } from '@/constants/dashboard';
import { PALETTE_COLOR } from '@/components/dashboard/home-pcaf/trend-line-chart/line-chart/define';
import { getUnit} from '@/utils/getTextWithCondition';
import { isTotalEmission } from '@/utils/pcaf';
import { math, formatBigNumber } from '@/concerns/newRegisterData/wijmo.helper';
import { formatValueUnit } from '@/concerns/chart';
import { mapState } from 'vuex';
const MILLION_YEN = 1000000;
export default {
  name: 'selectedChartData',
  computed: {
    ...mapState('settingsDashboard', ['itemsSettings']),
    getChartTitle() { // default year selection
      return this.$t("dashboard_main.label_title_column_chart_details_emissions_tab_year", {year: this.selectedChartData?.original?.year, type: 
        this.type === 1
        ? this.$t('dashboard_main.label_ghg_emission_in_sentence')
        : this.type === 3
        ? this.$t('dashboard_main.label_financed_emissions')
        : this.$t('dashboard_main.label_loan_amount_in_sentence_in_emissions_tab_year')})
    },
    getUnitState() {
      return this.itemsSettings[this.$store.state.userData.contractor]?.unitName || 't-CO2';
    },
    isTypeCanChangeUnit() {
      return this.type !== 2;
    },
    isTco2Unit() {
      return this.getUnitState === 't-CO2';
    },
    getYear() {
      return this.selectedChartData?.original?.year;
    },
    getTypeText() {
      return this.typeText;
    },
    getDuration() {
      return this.selectedChartData?.time_range;
    },
    getValueEmissions() {
      let totalValue = 0;
      if (isTotalEmission(this.type)) {
        totalValue = this.selectedChartData?.original?.total_emission_original;
      } else {
        let totalAmount = this.selectedChartData?.original?.total_amount?.toString();
        if (totalAmount === undefined || totalAmount === null) {
          return '-';
        }
        switch (this.currency) {
          case 1:
            totalValue = formatBigNumber(math.evaluate(`${totalAmount} / ${MILLION_YEN.toString()}`), 50);
            break;
          case 2:
           totalValue = formatBigNumber(math.evaluate(`${totalAmount} / ${"1000"}`), 50);
            break;
          case 3:
           totalValue = formatBigNumber(math.evaluate(`${totalAmount} / ${"1000"}`), 50);
            break;
          default:
            break;
        }
      }
      const formattedValue = this.isTypeCanChangeUnit ? formatValueUnit(totalValue, this.isTco2Unit) : totalValue;
      return this.formatNumber(formattedValue, { formatUnit: isTotalEmission(this.type) ? UNIT_SETTING.CO2 : UNIT_SETTING.CURRENCY });
    },
    unit() {
      return getUnit(this.type, this.currency, this.getUnitState);
    },
    isUpEmissions() {
      return this.selectedChartData.detail?.up_amount === 1 || this.selectedChartData.detail?.up_emission === 1;
    },
    imgSrc() {
      if (isTotalEmission(this.type)) {
        const imageSrc = this.selectedChartData.detail?.up_emission === 1 ? 'up.svg' : 'down.svg';
        return require(`@/assets/images/pcaf/emission/${imageSrc}`);
      }
      const imageSrc = this.selectedChartData.detail?.up_amount === 1 ? 'up.svg' : 'down.svg';
      return require(`@/assets/images/pcaf/amount/${imageSrc}`);
    },
    getRatio() {
      return this.selectedChartData?.detail?.ratio_amount || this.selectedChartData?.detail?.ratio_emission || '-';
    },
    getDescriptionText() {
      return this.selectedChartData?.type === 0 ? this.$t("dashboard_main.label_year_on_year_ratio") : this.$t("dashboard_main.label_month_to_month_basis");
    },
  },
  props: {
    selectedChartData: {
      type: Object,
      default: () => {},
    },
    trendTotalAssets: {
      type: Array,
      default: () => [],
    },
    isPopup: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Number,
      default: 0,
    },
    typeText: {
      type: String,
      default: '',
    },
    currency: {
      type: Number,
      default: 1
    }
  },
  mounted() {
    this.assetsTypes = this.assetsTypes.map((item, index) => ({
      text: item.text,
      borderColor: PALETTE_COLOR[index],
    }));
  },
  data() {
    return {
      assetsTypes: [
        { text: this.$t("dashboard_main.label_listed_stocks_bonds") },
        { text: this.$t("dashboard_main.label_corporate_loans_unlisted_quities") },
        { text: this.$t("dashboard_main.label_project_finance") },
        { text: this.$t("dashboard_main.label_commercial_real_estate") },
        { text: this.$t("dashboard_main.label_housing_loan") },
        { text: this.$t("dashboard_main.label_auto_loan") },
      ],
      unitSettings: UNIT_SETTING
    };
  },
  methods: {
    getValueAssets(value) {
      if(value === null || value === undefined) {
        return '-';
      }
      if (isTotalEmission(this.type)) {
        value = value;
      } else {
        if(value) {
          switch (this.currency) {
            case 1:
              value = formatBigNumber(math.evaluate(`${value?.toString()} / ${MILLION_YEN.toString()}`), 50);
              break;
            case 2:
              value = formatBigNumber(math.evaluate(`${value?.toString()} / ${"1000"}`), 50);
              break;
            case 3:
              value = formatBigNumber(math.evaluate(`${value?.toString()} / ${"1000"}`), 50);
              break;
            default:
              break;
          }
        }
      }
      const formattedValue = this.isTypeCanChangeUnit ? formatValueUnit(value, this.isTco2Unit) : value;
      return this.formatNumber(formattedValue, { formatUnit: isTotalEmission(this.type) ? UNIT_SETTING.CO2 : UNIT_SETTING.CURRENCY });
    },
    formatNumber(num, options) {
      if (num === 0) {
        return '---';
      }
      return formatNumberByConditions(num, options)
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-details.chart-popup {
    max-width: 100%;
    background: transparent;
}
.chart-details {
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 8px;
  background: $monoWhite;
  border-radius: 8px;
  min-width: 202px;
  min-height: 656px;
  border: 1px solid $monoLight;
  display: none;
  @include desktop {
    max-width: 230px;
  }
  &.chart-popup {
    display: block;
    max-width: 100%;
    background: transparent;
  }
  &-duration {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 6px;
    &__label {
      font-size: 11px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0.03em;
      text-align: left;
      color: $monoBlack;
      white-space: pre;
    }
    &__detail {
      font-size: 11px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0.03em;
      text-align: left;
      color: $monoBlack;
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    &_emission {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px 0px 19px;
      gap: 8px;
      // border-bottom: 1px solid rgba(42, 42, 48, 0.1);
      width: 100%;
      &__label {
        font-weight: 700;
        font-size: 11px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: $monoBlack;
      }
      &__value {
        font-weight: 400;
        font-size: 30px;
        line-height: 36px;
        letter-spacing: 0.03em;
        color: $monoBlack;
        word-break: break-all;
        font-family: "Century Gothic Pro";
        &--unit {
          font-size: 14px;
          line-height: 24px;
          display: inline-block;
          font-family: "Source Han Sans";
        }
      }
    }

    &_variation {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 8px 0px;
      gap: 8px;
      width: 100%;
      // border-bottom: 1px solid rgba(42, 42, 48, 0.1);
      &__label {
        font-weight: 700;
        font-size: 11px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: $monoBlack;
      }
      &__content {
        font-weight: 400;
        font-size: 30px;
        line-height: 36px;
        letter-spacing: 0.03em;
        color: $monoBlack;
        // height: 22px;
        &--value {
          word-break: break-all;
          font-family: "Century Gothic Pro";
        }
        &--percent {
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.05em;
          display: inline-block;
          margin: 12px 8px 0 8px;
        }
      }
    }

    &_ratio {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px 0px;
      gap: 8px;
      &__label {
        font-weight: 700;
        font-size: 11px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: $monoBlack;
      }
      &__content {
        font-weight: 400;
        font-size: 20px;
        line-height: 22px;
        letter-spacing: 0.05em;
        color: $goldMid;
        display: flex;
        align-items: center;
        gap: 8px;
        height: 22px;
        &--icon {
          cursor: pointer;
        }
        &--percent {
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.05em;
        }
      }
    }

    &_product {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      width: 100%;
      &_item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 8px;
        width: 100%;
        &--dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          min-width: 10px;
        }
        &--name {
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          letter-spacing: 0.05em;
          color: $monoBlack;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

@include desktop {
  .chart-details {
    display: flex;
  }
}

.chart-detail-statistic-assets {
  display: flex;
  flex-direction: column;
  width: 100%;
  .chart-detail-statistic-assets-item {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(42, 42, 48, 0.1);
  }
  .dot-color {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
  }
  .name-assets {
    font-size: 11px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.03em;
    text-align: left;
    color: rgba(64, 77, 80, 1);
  }
  &__contents {
    font-weight: 400;
    letter-spacing: 0.05em;
    color: $monoBlack;
    line-height: 21px;
    &__value {
      font-size: 20px;
      line-height: 18px;
      text-align: right;
      // display: inline-block;
      word-break: break-all;
      margin-right: 4px;
      font-family: "Century Gothic Pro";
    }
    &__unit {
      font-size: 11px;
      line-height: 18px;
      text-align: left;
    }
  }
}
</style>
