<template>
  <div class="chart-details" :class="{'chart-popup': isPopup }">
    <div class="chart-details-duration">
      <div class="chart-details-duration__label">{{getTitle}}</div>
    </div>

    <div class="chart-details-content">
      <div class="chart-details-content_emission">
        <div class="chart-details-content_emission__value">
          <span class="chart-details-content-text">{{ getTotalAllPattern }}</span>
          <span class="chart-details-content_emission__value--unit">{{ getUnit }}</span>
        </div>
      </div>

      <div class="chart-details-content_variation">
        <div class="chart-details-content_variation__label">{{$t('dashboard_main.label_emissions_by_industry_sector')}}</div>
      </div>

      <div class="chart-detail-statistic-assets">
        <div class="chart-detail-statistic-assets-item" v-for="(item, index) in data" :key="index">
          <div>
            <span class="dot-color" :style="{ backgroundColor: item.borderColor }"></span>
            <span class="name-assets">{{ getPcafName(item.item_name) }}</span>
          </div>
          <div class="chart-detail-container" @click="handleShowBreakDown(index)">
            <div class="chart-detail-content">
              <div class="left chart-detail-statistic-assets__contents">
                <span class="chart-detail-statistic-assets__contents__unit chart-detail-content-text">{{
                  totalPattern(item)
                }}</span>
                <span class="chart-detail-statistic-assets__contents__unit chart-detail-content-unit">{{ getUnit }}</span>
              </div>
              <div class="chart-detail-statistic-assets__contents chart-detail-circular">
                <div class="container-circular">
                  <v-progress-circular
                  :rotate="-90"
                  :size="16"
                  :width="2"
                  :value="percentPt(item)"
                  color="#A9A04B"
                ></v-progress-circular>
                </div>
                <div>
                  <span class="chart-detail-statistic-assets__contents__unit chart-detail-content-text">{{
                    percentPt(item)
                  }}</span>
                  <span class="chart-detail-statistic-assets__contents__unit chart-detail-content-unit percent-unit">%</span>
                </div>
              </div>
            </div>
            <div class="image-arrow">
              <img
                :class="index === showBreakdownIndex ? 'flipped-image' : ''"
                src="../../../../assets/images/chart-detail/select_arrow.svg"
                alt=""
                width="10"
                height="10"
              />
            </div>
          </div>
          <ChartDetailBreakdown :class="{'chart-popup': isPopup }" v-if="index === showBreakdownIndex" :dataChartDetail="item" :tab="tab" :type="type" :currency="currency"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatBigNumber, math, formatValue } from '@/concerns/newRegisterData/wijmo.helper';
import { formatNumberByConditions } from '@/utils/convertNumber';
import ChartDetailBreakdown from '@/components/dashboard/home-pcaf/pie-industry-chart/chart-detail-breakdown';
import { getUnit } from '@/utils/getTextWithCondition';
import { isTotalEmission } from '@/utils/pcaf';
import { formatValueUnit } from '@/concerns/chart';
import { mapState } from 'vuex';
import { UNIT_SETTING } from '@/constants/dashboard';

export default {
  name: 'selectedChartData',
  components: { ChartDetailBreakdown },
  props: {
    selectedChartData: {
      type: Object,
      default: () => {},
    },
    trendTotalAssets: {
      type: Array,
      default: () => [],
    },
    type: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    generateColor: {
      type: Array,
      default: () => [],
    },
    isPopup: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      showBreakdownIndex: null,
      data: [],
    };
  },
  mounted() {
    this.convertData();
  },
  watch: {
    selectedChartData(val) {
      this.selectedChartData = val;
      this.convertData()
    },
  },
  computed: {
    ...mapState('settingsDashboard', ['itemsSettings']),
    getUnitState() {
      return this.itemsSettings[this.$store.state.userData.contractor]?.unitName || 't-CO2';
    },
    isTypeCanChangeUnit() {
      return this.type !== 2;
    },
    isTco2Unit() {
      return this.getUnit === 't-CO2';
    },
    getUnit() {
      return getUnit(this.type, this.currency, this.getUnitState);
    },
    getTotalAllPattern() {
      let totalValue = 0;
      if(isTotalEmission(this.type)) {
        totalValue = this.selectedChartData?.totalEmissionGhg?.total_emission_all_pattern;
      } else {
        switch (this.currency) {
          case 1:
            totalValue = formatBigNumber(math.evaluate(`${math.bignumber(formatValue(this.selectedChartData?.totalEmissionGhg?.total_amount_all_pattern)) / 1000000}`), 50);
            break;
          case 2:
            totalValue = formatBigNumber(math.evaluate(`${math.bignumber(formatValue(this.selectedChartData?.totalEmissionGhg?.total_amount_all_pattern)) / 1000}`), 50);
            break;
          case 3:
            totalValue = formatBigNumber(math.evaluate(`${math.bignumber(formatValue(this.selectedChartData?.totalEmissionGhg?.total_amount_all_pattern)) / 1000}`), 50);
            break;
          default:
            break;
        }
      }
      const formattedTotalValue = this.isTypeCanChangeUnit ? formatValueUnit(totalValue, this.isTco2Unit) : totalValue;
      return formatNumberByConditions(formattedTotalValue, {
        formatUnit: isTotalEmission(this.type) ? UNIT_SETTING.CO2 : UNIT_SETTING.CURRENCY,
      });
    },
    totalPattern() {
      return function (item) {
        let totalValue = 0;
        if (isTotalEmission(this.type)) {
          totalValue = item?.total_emission_pattern;
        } else {
          switch (this.currency) {
            case 1:
              totalValue = formatBigNumber(math.evaluate(`${math.bignumber(formatValue(item?.total_amount_pattern)) / 1000000}`), 50);
              break;
            case 2:
              totalValue = formatBigNumber(math.evaluate(`${math.bignumber(formatValue(item?.total_amount_pattern)) / 1000}`), 50);
              break;
            case 3:
              totalValue = formatBigNumber(math.evaluate(`${math.bignumber(formatValue(item?.total_amount_pattern)) / 1000}`), 50);
              break;
            default:
              break;
          }
        }
        const formattedTotalValue = this.isTypeCanChangeUnit
          ? formatValueUnit(totalValue, this.isTco2Unit)
          : totalValue;
          return formatNumberByConditions(formattedTotalValue, {
              formatUnit: isTotalEmission(this.type) ? UNIT_SETTING.CO2 : UNIT_SETTING.CURRENCY,
            });
      };
    },
    percentPt() {
      return function (item) {
        const percent = isTotalEmission(this.type) ? item?.percent_emission_pt : item?.percent_amount_pt;
        return formatNumberByConditions(percent, { formatUnit: UNIT_SETTING.PERCENT });
      };
    },
    getTitle() {
      return isTotalEmission(this.type) ? this.$t("dashboard_main.label_total_emissions") : this.$t("dashboard_main.label_investment_and_financing_amount");
    },
  },

  methods: {
    convertData() {
      this.data = this.selectedChartData?.data?.map((item, index) => ({
        ...item,
        borderColor: this.generateColor[index],
        isShowBreakDown: false,
      }));
    },
    handleShowBreakDown(index) {
      if (this.showBreakdownIndex === index) {
        this.showBreakdownIndex = null;
      } else {
        this.showBreakdownIndex = index;
      }
    },
    formatNumber(num, type) {
      if (num === 0) {
        return '---';
      }
      return formatNumberByConditions(num)
    },
    
    getPcafName(name) {
      return name;
    }
  },
};
</script>

<style lang="scss" scoped>
.chart-details {
  height: fit-content;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 8px;
  background: $monoWhite;
  border-radius: 8px;
  min-width: 327px;
  border: 1px solid $monoLight;
  &.chart-popup {
    max-width: 100%;
    background: transparent;
    display: flex;
    border: unset;
  }
  &-duration {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 6px;
    &__label {
      font-size: 11px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0.03em;
      text-align: left;
      color: $monoBlack;
    }
    &__detail {
      font-size: 11px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0.03em;
      text-align: left;
      color: $monoBlack;
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    &_emission {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px 0px 16px;
      gap: 8px;
      // border-bottom: 1px solid rgba(42, 42, 48, 0.1);
      width: 100%;

      &__label {
        font-weight: 700;
        font-size: 11px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: $monoBlack;
      }
      &__value {
        font-weight: 400;
        font-size: 30px;
        line-height: 36px;
        letter-spacing: 0.03em;
        color: $monoBlack;
        word-break: break-all;

        &--unit {
          font-size: 14px;
          line-height: 24px;
          margin-left: 8px;
          display: inline-block;
        }
      }
    }
    &_variation {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 8px 0px;
      gap: 8px;
      width: 100%;
      // border-bottom: 1px solid rgba(42, 42, 48, 0.1);
      &__label {
        font-weight: 700;
        font-size: 11px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: $monoBlack;
      }
      &__content {
        font-weight: 400;
        font-size: 30px;
        line-height: 36px;
        letter-spacing: 0.03em;
        color: $goldMid;
        display: flex;
        align-items: center;
        gap: 8px;
        // height: 22px;
        &--value {
          word-break: break-all;
        }
        &--percent {
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.05em;
          margin-top: 12px;
        }
      }
    }

    &_ratio {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px 0px;
      gap: 8px;

      &__label {
        font-weight: 700;
        font-size: 11px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: $monoBlack;
      }

      &__content {
        font-weight: 400;
        font-size: 20px;
        line-height: 22px;
        letter-spacing: 0.05em;
        color: $goldMid;
        display: flex;
        align-items: center;
        gap: 8px;
        height: 22px;

        &--icon {
          cursor: pointer;
        }

        &--percent {
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.05em;
        }
      }
    }

    &_product {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      width: 100%;

      &_item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 8px;
        width: 100%;

        &--dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          min-width: 10px;
        }

        &--name {
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          letter-spacing: 0.05em;
          color: $monoBlack;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .chart-details-content-text {
    font-family: "Century Gothic Pro";
  }
}
.chart-detail-statistic-assets {
  display: flex;
  flex-direction: column;
  width: 100%;
  .chart-detail-statistic-assets-item {
    display: flex;
    flex-direction: column;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(42, 42, 48, 0.1);
  }
  .dot-color {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
  }
  .name-assets {
    // font-family: 'Source Han Sans JP';
    text-align: left;
    color: $monoDark;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 183.333% */
    letter-spacing: 0.6px;
  }
  &__contents {
    font-weight: 400;
    letter-spacing: 0.05em;
    color: $monoBlack;
    &__value {
      font-size: 20px;
      line-height: 22px;
      text-align: right;
      display: inline-block;
      margin-right: 4px;
    }
    &__unit {
      font-size: 11px;
      line-height: 18px;
      text-align: left;
    }
  }
}
.chart-detail-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  flex: 1;
  max-width: 90%;
  .left {
    flex: 1 1 auto;
    min-width: 0;
    word-wrap: break-word;
  }
}

.flipped-image {
  transform: scale(-1);
}
.chart-detail-container {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  width: 100%;
}
.image-arrow {
  display: flex;
  justify-content: center;
  width: 48px;
}
.container-circular{
  display: flex;
}
.chart-detail-circular {
  margin-right: 0;
  display: flex;
  align-items: center;
  gap: 6px;
}
.chart-detail-content-text {
  font-family: Century Gothic Pro;
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.05em;
  text-align: justify;
  margin-right: 4px;
  word-wrap: break-word;
}
.chart-detail-content-unit {
  // font-family: Source Han Sans JP;
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.05em;
  text-align: left;
  // display: block;
  width: 100%;
  max-width: 35px;
  margin-top: 4px;
  word-break: break-word;
  white-space: pre;
}
.percent-unit {
  width: 8px;
}
@include desktop {
  .chart-details {
    display: flex;
    width: 327px;
  }
}
@include large-desktop {
  .chart-details {
    display: flex;
  }
}

@media (max-width: 1023px) {
  .chart-details {
    border: none;
  }
}
</style>
