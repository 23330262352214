<template>
  <div class="chart-details" :class="{'chart-popup': isPopup }">
    <div class="chart-title">
      <div class="chart-title__title">{{ chartTitle }}</div>
      <div class="chart-title__detail">
        <span class="chart-title__unit">{{ total }}</span>
        <span class="chart-title__unit_suffix">{{ unit }}</span>
      </div>
    </div>
    <div class="chart-ratio">
      <div class="chart-title__title">{{ ratioTitle }}</div>
      <div class="chart-ratio__detail">
        <div class="chart-title__unit">{{ ratio }}</div>
        <div v-if="ratio !== '-'" class="chart-title__unit_suffix">%</div>
        <div v-if="ratio !== '-'" class="chart-title_image">
          <img :src="imgSrc" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatNumberByConditions } from '@/utils/convertNumber';
import { getTitle, getUnit, chartTitleMonthYearInvestMent, chartTitleMonthYearGHG, chartTitleMonthYearfinanced } from '@/utils/getTextWithCondition';
import { isTotalEmission, calcTotalValue } from '@/utils/pcaf';
import { mapState } from 'vuex';
import { UNIT_SETTING } from '@/constants/dashboard';

export default {
  props: {
    selectedChartData: {
      type: Object,
      default: () => {},
    },
    type: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    typeText: {
      type: String,
      default: '',
    },
    isPopup: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: Number,
      default: 1,
    }
  },
  computed: {
    ...mapState('settingsDashboard', ['itemsSettings']),
    chartTitle() {
      let title = '';
      let yearSelected = this.selectedChartData.year;
      switch (this.tab) {
        case 0:
          yearSelected = this.selectedChartData.year;
          break;
        case 1:
        case 2:
          yearSelected = this.selectedChartData.year_order;
          break;
        default:
          yearSelected = this.selectedChartData.year;
          break;
      }
      if (this.typeText === this.$t("dashboard_main.radio_select_ghg_emissions_")) {
        title = chartTitleMonthYearGHG(this.tab, yearSelected, this.selectedChartData.order, this.selectedChartData.month)
      } else if (this.typeText === this.$t("dashboard_main.radio_select_financed_emissions")) {
        title = chartTitleMonthYearfinanced(this.tab, yearSelected, this.selectedChartData.order, this.selectedChartData.month)
      } else {
        title = chartTitleMonthYearInvestMent(this.tab, yearSelected, this.selectedChartData.order, this.selectedChartData.month)
      }
      return title;
    },
    total() {
      const { total_emission, total_amount } = this.selectedChartData || {};
      if (!total_emission && !total_amount) return;

      const total = calcTotalValue({ type: this.type, currency: this.currency }, total_emission, total_amount);
      return total === null || total === 0
        ? '-'
        : formatNumberByConditions(total, {
            formatUnit: [1, 3].includes(this.type) ? UNIT_SETTING.CO2 : UNIT_SETTING.CURRENCY,
          });
    },

    getUnitState() {
      return this.itemsSettings[this.$store.state.userData.contractor]?.unitName || 't-CO2';
    },
    unit() {
      return getUnit(this.type, this.currency, this.getUnitState);
    },
    ratioTitle() {
      return getTitle(this.tab);
    },
    ratio() {
      const ratio = isTotalEmission(this.type) ? this.selectedChartData?.ratio_emission : this.selectedChartData?.ratio_amount;
      return ratio !== null ? formatNumberByConditions(ratio, { formatUnit: UNIT_SETTING.PERCENT }) : '-';
    },
    imgSrc() {
      if (isTotalEmission(this.type)) {
        const imageSrc = this.selectedChartData.up_emission === 1 ? 'up.svg' : 'down.svg';
        return require(`@/assets/images/pcaf/emission/${imageSrc}`);
      }
      const imageSrc = this.selectedChartData.up_amount === 1 ? 'up.svg' : 'down.svg';
      return require(`@/assets/images/pcaf/amount/${imageSrc}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.chart-details {
  background: $monoWhite;
  min-width: 197px;
  padding: 24px;
  border-radius: 8px;
  gap: 32px;
  border: 1px solid $monoLight;
  @include desktop {
    max-width: 230px;
  }
  &.chart-popup {
    background: transparent;
    border: unset;
  }
}
.chart-title {
  margin-bottom: 32px;
  &__title {
    // // font-family: Source Han Sans JP;
    font-size: 11px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.03em;
    text-align: left;
    white-space: pre-wrap;
    margin-bottom: 8px;
    color: $monoBlack;
  }
  &__unit {
    font-family: Century Gothic Pro;
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0.03em;
    text-align: left;
    color: $monoBlack;
    word-break: break-all;
    display: inline;
  }
  &__unit_suffix {
    // font-family: Source Han Sans JP;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-align: left;
    color: $monoBlack;
    margin-left: 8px;
    display: inline-block;
  }
}
.chart-title__detail {
  word-break: break-all;
}
.chart-title_image {
  margin-left: 10px;
  display: inline;
}
.chart-image-display {
  display: none;
}
.hidden-chart-image {
  display: none;
}
</style>
