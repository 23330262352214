<template>
  <div class="chart-details" :class="{ 'chart-popup': isPopup }">
    <div class="chart-details-duration">
      <div class="chart-details-duration__label">{{ titleValue }}</div>
      <div class="chart-details-duration__detail">{{ nameAssetsValue }}</div>
    </div>

    <div class="chart-details-content">
      <div class="chart-details-content_emission">
        <div class="chart-details-content_emission__value">
          {{ getEmissionValue }}
          <span class="chart-details-content_emission__value--unit">{{ unit }}</span>
        </div>
      </div>

      <div class="chart-details-content_variation">
        <div class="chart-details-content_variation__label">{{ ratioTitle }}</div>
        <div class="chart-details-content_variation__content">
          <div class="chart-details-content_variation__content">
            <span class="chart-details-content_variation__content--value">{{
              formatNumber(ratioValue, { type: 'chart-detail-ratio', formatUnit: unitSetting.PERCENT })
            }}</span>
            <span v-if="ratioValue && ratioValue !== '-'" class="chart-details-content_variation__content--percent">%</span>
            <img
              v-if="ratioValue && ratioValue !== '-'"
              :src="imgSrcValue"
              alt=""
              class="chart-details-content_variation__content--icon"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatNumberByConditions } from '@/utils/convertNumber';
import { UNIT_SETTING } from '@/constants/dashboard';
import { getUnit as getUnitText } from '@/utils/getTextWithCondition';
import { isTotalEmission, calcTotalValue } from '@/utils/pcaf';
import { mapState } from 'vuex';
import { formatValueUnit } from '@/concerns/chart';

export default {
  computed: {
    ...mapState('settingsDashboard', ['itemsSettings']),
    getUnit() {
      return this.itemsSettings[this.$store.state.userData.contractor]?.unitName || 't-CO2';
    },
    ratioTitle() {
      return this.$t("dashboard_main.label_year_on_year_ratio");
    },
    unit() {
      return getUnitText(this.type, this.currency, this.getUnit);
    },
    isTypeCanChangeUnit() {
      return this.type !== 2;
    },
    isTco2Unit() {
      return this.getUnit === 't-CO2';
    },
    getEmissionValue() {
      if (this.emissionValue === 0) {
        return '-';
      }

      const formattedValue = this.isTypeCanChangeUnit
        ? formatValueUnit(this.emissionValue, this.isTco2Unit)
        : this.emissionValue;
      return this.formatNumber(formattedValue, { formatUnit: UNIT_SETTING.CO2});
    },
  },
  props: {
    selectedChartData: {
      type: Object,
      default: () => {},
    },
    trendTotalAssets: {
      type: Array,
      default: () => [],
    },
    isPopup: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Number,
      default: 0,
    },
    typeText: {
      type: String,
      default: '',
    },
    currency: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      titleValue: null,
      nameAssetsValue: null,
      emissionValue: null,
      imgSrcValue: null,
      ratioValue: null,
      unitSetting: UNIT_SETTING
    }
  },
  mounted() {
    this.calcDataDetail();
  },
  methods: {
    chartTitle() {
      return this.$t("dashboard_main.label_title_column_chart_details_emissions_tab_year", {year: this.selectedChartData.year, type: this.typeText})
    },
    nameAssets() {
      return `${this.selectedChartData.nameAssets.replace('・', '/')}`;
    },
    getValueEmissions() {
      return calcTotalValue({type: this.type, currency: this.currency}, this.selectedChartData?.value, this.selectedChartData?.value);
    },
    imgSrc() {
      if (isTotalEmission(this.type)) {
        const imageSrc = this.selectedChartData.detail?.up_emission === 1 ? 'up.svg' : 'down.svg';
        return require(`@/assets/images/pcaf/emission/${imageSrc}`);
      }
      const imageSrc = this.selectedChartData.detail?.up_amount === 1 ? 'up.svg' : 'down.svg';
      return require(`@/assets/images/pcaf/amount/${imageSrc}`);
    },
    getRatio() {
      return this.selectedChartData?.detail?.ratio_amount || this.selectedChartData?.detail?.ratio_emission || '-';
    },
    formatNumber(num, options) {
      if (num === 0) {
        return '---';
      }
      return formatNumberByConditions(num, options);
    },
    calcDataDetail() {
      this.titleValue = this.chartTitle();
      this.nameAssetsValue = this.nameAssets();
      this.emissionValue = this.getValueEmissions();
      this.imgSrcValue = this.imgSrc();
      this.ratioValue = this.getRatio();
    }
  },
  watch: {
    selectedChartData: {
      handler() {
        this.calcDataDetail();
      },
      deep: true
    }
  },
};
</script>

<style lang="scss" scoped>
.chart-details.chart-popup {
  max-width: 100%;
  background: transparent;
}
.chart-details {
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 8px;
  background: $monoWhite;
  border-radius: 8px;
  height: calc(459px - 27px - 24px);
  display: none;
  &.chart-popup {
    max-width: 100%;
    background: transparent;
    display: block;
  }
  &-duration {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 6px;
    &__label {
      font-size: 11px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0.03em;
      text-align: left;
      color: $monoBlack;
    }
    &__detail {
      font-size: 11px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0.03em;
      text-align: left;
      color: $monoBlack;
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    &_emission {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px 0px 16px;
      gap: 8px;
      // border-bottom: 1px solid rgba(42, 42, 48, 0.1);
      width: 100%;
      &__label {
        font-weight: 700;
        font-size: 11px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: $monoBlack;
      }
      &__value {
        font-weight: 400;
        font-size: 30px;
        line-height: 36px;
        letter-spacing: 0.03em;
        color: $monoBlack;
        word-break: break-all;
        font-family: "Century Gothic Pro";
        &--unit {
          font-size: 14px;
          line-height: 24px;
          margin-left: 8px;
          display: inline-block;
          font-family: "Source Han Sans";
        }
      }
    }

    &_variation {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px 0px;
      gap: 8px;
      width: 100%;
      // border-bottom: 1px solid rgba(42, 42, 48, 0.1);
      &__label {
        font-weight: 700;
        font-size: 11px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: $monoBlack;
      }
      &__content {
        font-weight: 400;
        font-size: 30px;
        line-height: 36px;
        letter-spacing: 0.03em;
        color: $monoBlack;
        display: flex;
        align-items: center;
        gap: 8px;
        // height: 22px;
        &--value {
          word-break: break-all;
          font-family: "Century Gothic Pro";
        }
        &--percent {
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.05em;
          margin-top: 12px;
        }
      }
    }

    &_ratio {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px 0px;
      gap: 8px;
      &__label {
        font-weight: 700;
        font-size: 11px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: $monoBlack;
      }
      &__content {
        font-weight: 400;
        font-size: 20px;
        line-height: 22px;
        letter-spacing: 0.05em;
        color: $goldMid;
        display: flex;
        align-items: center;
        gap: 8px;
        height: 22px;
        &--icon {
          cursor: pointer;
        }
        &--percent {
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.05em;
        }
      }
    }
  }
}

@include desktop {
  .chart-details {
    display: flex;
  }
}

@media (max-width: 1448px) {
  .chart-details {
    height: calc(459px - 27px);
  }
}
</style>
